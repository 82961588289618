/*eslint-disable no-unused-vars */

import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {CircularProgress, Modal} from "@mui/material";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import VideoThumbnailCard from "./components/VideoThumbnailCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import ComplexProjectCard from "./components/Cards/ProjectCards/ComplexProjectCard";

// Project page components
import Header from "./components/Header";

import { useEffect } from "react";

// Images
import team1 from "assets/images/avatar1.png";
import team2 from "assets/images/avatar2.png";
import team3 from "assets/images/avatar3.png";
import team4 from "assets/images/avatar4.png";
import team5 from "assets/images/avatar5.png";

// Icons
import { BsSlack, BsSpotify } from "react-icons/bs";
import { SiAdobexd, SiAtlassian } from "react-icons/si";
import VideoPopup from "./components/VideoPopup";

import videoData from "./data/videoData";
import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { Card, DialogContent, Icon, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Upload from "./components/AddNewPopup/Upload";
import { Opacity, Person } from "@mui/icons-material";
import { IoMdMore } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { GiPlayButton } from "react-icons/gi";
import ReactPlayer from "react-player";
import axios from "axios";
import { useVisionUIController } from "context";
import BASE_URL from "context/api";
import { useSnackbar } from "notistack";
import { NavLink, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "Pagination";
import QRCode from 'qrcode.react';
import VuiInput from "components/VuiInput";
import folderIcon from './folder_5082687.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { setAllVideos, setAllEditorVideos } from "context";
import DiamondIcon from '@mui/icons-material/Diamond';
import mixpanel from "mixpanel-browser";
import { setAllSaasVideos } from "context";
import { setDrafts } from "context";

const emails = ["awfouhi", "aefgukaoij"];

function CreateNewProject(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const [curView, setCurView] = useState("newProject");
  const [subview, setSubview] = useState("");

  const freshProject = () => {
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Start with a New Project</DialogTitle>
      <Card>
        <List sx={{ pt: 0 }}>
          {emails.map((email) => (
            <ListItem
              button
              onClick={() => handleListItemClick(email)}
              key={email}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} />
            </ListItem>
          ))}
          <ListItem
            autoFocus
            button
            onClick={() => handleListItemClick("addAccount")}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItem>
        </List>
      </Card>
    </Dialog>;
  };

  return (
    <Dialog
      maxWidth="lg"
      maxHeight="lg"
      onClose={handleClose}
      open={open}
      minwidth="lg"
      minHeight="lg"
    >
      <DialogTitle
        style={{
          background: "linear-gradient(to bottom, #1c2e44, #344767)",
          color: "whitesmoke",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Create QuickVideo
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ color: "whitesmoke" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          background: "linear-gradient(to top, #1c2e44, #344767)",
          // color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        <Upload />
      </DialogContent>
    </Dialog>
  );
}

function AllVideos(props) {
  let queryVid = props.location.search.replace("?queryVid=", "");
  const [currentVid, setCurrentVid] = useState(queryVid ? queryVid : null);
  let projectData = videoData;
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [allProjects, setAllProjects] = useState(projectData);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
  const [newProject, setNewProject] = useState(false);
  // const [allVideos, setAllVideos] = useState([]);
  // const [allEditorVideos, setAllEditorVideos] = useState([]);
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, postLoading, allVideos, allEditorVideos, allSaasVideos, plan, drafts } = controller;
  const { user_id, access_token } = userDetails;
  const [loading, setLoading] = useState(false);
  const [iconLoading, setIconLoading] = useState(false);
  const [editInput, setEditInput] = useState(false);
  const [newFilename, setNewFilename] = useState("");
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [ showModal, setShowModal] = useState(false);
  const [deleteVideoId, setDeleteVideoId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [videoLink, setVideoLink] = useState('');
  const [QRVideoModal, setQRVideoModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const videoType = ['Editor', 'Presenter', 'Saas', 'Draft'];
  const [videoOpen, setVideoOpen] = useState({
    Editor: false,
    Presenter: false,
    Saas: false,
    Draft: false,
  }); 
  const [copy, setCopy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { Presenter, Editor, Saas, Draft } = location.state || {};

  useEffect(() => {
    if (Presenter) {
      setVideoOpen({
        ...videoOpen,
        Presenter: true,
      });
    } 
    
    if (Editor) {
      setVideoOpen({
        ...videoOpen,
        Editor: true,
      });
    } 
    
    if (Saas) {
      setVideoOpen({
        ...videoOpen,
        Saas: true,
      });
    } 
    
    if (Draft) {
      setVideoOpen({
        ...videoOpen,
        Draft: true,
      });
    } 
  }, []);

  const anyVideoOpen = Object.values(videoOpen).some(value => value === true);
  const findTrueKey = (obj) => {
    for (let key in obj) {
      if (obj[key] === true) {
        return key;
      }
    }
    return null;
  };
  const findTrueValues = findTrueKey(videoOpen);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const itemsPerPage = 11;
  const filteredVideos = allVideos && allVideos.filter((video) => video.filename.toLowerCase().includes(searchQuery.toLowerCase()));
  const filteredEditorVideos = allEditorVideos && allEditorVideos.filter((video) => video.video_name.toLowerCase().includes(searchQuery.toLowerCase()));
  const filteredSaasVideos = allSaasVideos && allSaasVideos.filter((video) => video.video_name.toLowerCase().includes(searchQuery.toLowerCase()));


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedVideos = filteredVideos.slice(startIndex, endIndex);
  const editorVideos = filteredEditorVideos.slice(startIndex, endIndex);
  const saasVideos = filteredSaasVideos.slice(startIndex, endIndex);

  const fetchData = async () => {
    setLoading(true);
    const userId = user_id;
    try {
      const response = await axios.get(`${BASE_URL}/ai_videos`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllVideos(dispatch, response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  
  const fetchEditorData = async () => {
    setLoading(true);
    const userId = user_id;
    try {
      const response = await axios.get(`${BASE_URL}/video_details`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllEditorVideos(dispatch, response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  
  const fetchSaasData = async () => {
    setLoading(true);
    const userId = user_id;
    try {
      const response = await axios.get(`${BASE_URL}/saas_video`, {
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllSaasVideos(dispatch, response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  //   fetchEditorData();
  //   // eslint-disable-next-line
  // }, [currentPage, postLoading]);

  const handleVideoType = (type) => {
    setVideoOpen({ ...videoOpen, [type]: !videoOpen[type] });
  };

  const handleCloseVideoOpen = () => {
    setVideoOpen({
      Editor: false,
      Presenter: false,
      Saas: false,
    })
  };

  const handleClickOpen = () => {
    setNewProject(true);
  };

  const handleClose = () => {
    setNewProject(false);
  };

  useEffect(() => {
    if (currentVid) {
      setCurrentlyPlaying(allVideos.find((vid) => vid.video_id === currentVid));
    }
    //eslint-disable-next-line
  }, []);

  function handleVideoClosed() {
    setCurrentlyPlaying(false);
  }

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);

  const handleToggleEdit = (videoId) => {
    setEditInput(true);
    setEditingVideoId(videoId);
    const video = allVideos.find((v) => v.video_id === videoId);
    if (video) {
      setNewFilename(video.filename);
    }
  };

  const handleWishlist = async (videoId, wishlist, type) => {
    const video_id = videoId;
    const video = allVideos.find((v) => v.video_id === videoId);
    const editorVideo = allEditorVideos.find((v) => v.id === video_id);
    const saasVideo = allSaasVideos.find((v) => v.id === video_id);

    let BASE_TYPE;
    switch (type) {
      case "Presenter":
        BASE_TYPE = 'ai_video_wishlist';
        break;
      case "Editor":
        BASE_TYPE = 'video_wishlist';
        break;
      case "Saas":
        BASE_TYPE = 'saas_video_wishlist';
        break;
      default:
        break;
    }
    if(type === 'Presenter') {
      if (video) {
        if(wishlist) {
          setLoading(true);
          try {
            const response = await axios.delete(`${BASE_URL}/${BASE_TYPE}`, {
              params: {
                user_id,
                video_id,
              }
            });
            enqueueSnackbar("Video removed from wishlist.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            fetchData();
          } catch (error) {
            enqueueSnackbar(error.response.data.detail, {
              variant: "warning",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            throw error;
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(true);
          try {
            const response = await axios.post(`${BASE_URL}/${BASE_TYPE}`, {
              user_id,
              video_id,
            });
            enqueueSnackbar("Video wishlisted successfully.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            mixpanel.track('Video favourite added', {
              'Video id': video_id,
            });
            fetchData();
          } catch (error) {
            console.error("API request failed", error.message);
            enqueueSnackbar(error.response.data.detail, {
              variant: "warning",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            throw error;
          } finally {
            setLoading(false);
          }
        }
      }
    }
    
    if(type === 'Editor') {
      if (editorVideo) {
        if(wishlist) {
          setLoading(true);
          try {
            const response = await axios.delete(`${BASE_URL}/${BASE_TYPE}`, {
              params: {
                user_id,
                video_id,
              }
            });
            enqueueSnackbar("Video removed from wishlist.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            fetchEditorData();
          } catch (error) {
            enqueueSnackbar(error.response.data.detail, {
              variant: "warning",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            throw error;
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(true);
          try {
            const response = await axios.post(`${BASE_URL}/${BASE_TYPE}`, {
              user_id,
              video_id,
            });
            enqueueSnackbar("Video wishlisted successfully.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            fetchEditorData();
          } catch (error) {
            console.error("API request failed", error.message);
            enqueueSnackbar(error.response.data.detail, {
              variant: "warning",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            throw error;
          } finally {
            setLoading(false);
          }
        }
      }
    }
    
    if(type === 'Saas') {
      if (saasVideo) {
        if(wishlist) {
          setLoading(true);
          try {
            const response = await axios.delete(`${BASE_URL}/${BASE_TYPE}`, {
              params: {
                user_id,
                video_id,
              }
            });
            enqueueSnackbar("Video removed from wishlist.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            fetchSaasData();
          } catch (error) {
            enqueueSnackbar(error.response.data.detail, {
              variant: "warning",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            throw error;
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(true);
          try {
            const response = await axios.post(`${BASE_URL}/${BASE_TYPE}`, {
              user_id,
              video_id,
            });
            enqueueSnackbar("Video wishlisted successfully.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            fetchSaasData();
          } catch (error) {
            console.error("API request failed", error.message);
            enqueueSnackbar(error.response.data.detail, {
              variant: "warning",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            throw error;
          } finally {
            setLoading(false);
          }
        }
      }
    }
  }

  const handleSaveFilename = async (video_id, type) => {
    const video = allVideos.find((v) => v.video_id === video_id);
    const editorVideo = allEditorVideos.find((v) => v.id === video_id);
    const saasVideo = allSaasVideos.find((v) => v.id === video_id);
    
    let BASE_TYPE;
    switch (type) {
      case "Presenter":
        BASE_TYPE = 'ai_videos';
        break;
      case "Editor":
        BASE_TYPE = 'update_video_info';
        break;
      case "Saas":
        BASE_TYPE = 'saas_update_video_info';
        break;
      default:
        break;
    }
    if(type === 'Presenter') {
      if (video && newFilename !== "") {
        try {
          setIconLoading(true);
          await axios.patch(`${BASE_URL}/${BASE_TYPE}`, {
            video_id,
            new_filename: newFilename,
            user_id
          });
          await fetchData();
          // setAllVideos((prevVideos) =>
          //   prevVideos.map((v) => {
          //     if (v.video_id === video_id) {
          //       return { ...v, filename: newFilename };
          //     }
          //     return v;
          //   })
          // );
          
          enqueueSnackbar('File name changed.', { 
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } catch (error) {
          console.error("Error updating filename:", error);
          enqueueSnackbar(error.response.data.detail, {
            variant: "warning",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          setIconLoading (false);
        }
      }
    }
    if(type === 'Editor') {
      if (editorVideo && newFilename !== "") {
        try {
          setIconLoading(true);
          await axios.patch(`${BASE_URL}/${BASE_TYPE}`, {
            video_id,
            new_filename: newFilename,
            user_id
          });
          await fetchEditorData();
          // setAllEditorVideos((prevVideos) =>
          //   prevVideos.map((v) => {
          //     if (v.id === video_id) {
          //       return { ...v, filename: newFilename };
          //     }
          //     return v;
          //   })
          // );
          enqueueSnackbar('File name changed.', { 
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } catch (error) {
          console.error("Error updating filename:", error);
          enqueueSnackbar(error.response.data.detail, {
            variant: "warning",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          setIconLoading (false);
        }
      }
    }
    if(type === 'Saas') {
      if (saasVideo && newFilename !== "") {
        try {
          setIconLoading(true);
          await axios.patch(`${BASE_URL}/${BASE_TYPE}`, {
            video_id,
            new_filename: newFilename,
            user_id
          });
          await fetchSaasData();
          // setAllEditorVideos((prevVideos) =>
          //   prevVideos.map((v) => {
          //     if (v.id === video_id) {
          //       return { ...v, filename: newFilename };
          //     }
          //     return v;
          //   })
          // );
          enqueueSnackbar('File name changed.', { 
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } catch (error) {
          console.error("Error updating filename:", error);
          enqueueSnackbar(error.response.data.detail, {
            variant: "warning",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          setIconLoading (false);
        }
      }
    }
    setEditingVideoId(null);
    setNewFilename("");
  }; 

  const deleteApi = async (video_id, type) => {
    let BASE_TYPE;
    switch (type) {
      case "Presenter":
        BASE_TYPE = 'ai_videos';
        break;
      case "Editor":
        BASE_TYPE = 'update_video_info';
        break;
      case "Saas":
        BASE_TYPE = 'saas_update_video_info';
        break;
      default:
        break;
    }
    setLoading(true);
    try {
      await axios.delete(`${BASE_URL}/${BASE_TYPE}`, {
        params: {
          video_id,
          user_id
        }
      });
      handleClose();
      setDeleteVideoId('');
      fetchData();
      fetchEditorData();
      fetchSaasData();
      enqueueSnackbar('Video deleted.', { 
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      handleClose();
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const DeleteModal = ({video_id}) => {
    const [open, setOpen] = useState(true);
    //eslint-disable-next-line
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setShowModal(false);
    };
  
    const handleYes = () => {
      deleteApi(video_id, findTrueValues);
      handleClose();
    };
  
    const handleNo = () => {
      setDeleteVideoId('');
      handleClose();
    };
  
    return (
      <div>
        <Modal open={showModal} onClose={handleClose}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "15px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "25vh",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 2px",
            borderRadius: '5px'
          }}>
            <div>
              <h3 style={{padding: "5px", color: "whitesmoke", fontWeight: "400"}}>Are you sure want to delete the video ?</h3>
            </div>
            <div style={{display: "flex", width: "100%", alignItems:"center", justifyContent: "space-evenly"}}>
              <Button 
                variant='contained' 
                sx={{
                  color: "whitesmoke", 
                  background: "#344767", 
                  minWidth: "130px",
                  fontSize: "14px",
                  ":hover": {
                    bgcolor: "#344767",
                    color: "white"
                  }
                }} 
                onClick={handleYes}
              >
                Yes
              </Button>
              <Button 
                variant='contained' 
                sx={{
                  color: "whitesmoke", 
                  background: "#344767", 
                  minWidth: "130px",
                  fontSize: "14px",
                  ":hover": {
                    bgcolor: "#344767",
                    color: "white"
                  }
                }} 
                onClick={handleNo}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopy(true);
    setTimeout(() => setCopy(false), 3000);
  }

  const UpgradeModal = () => {
    const handleClose = () => {
      setUpgradeModal(false);
    };
  
    return (
      <div>
        <Modal open={upgradeModal} onClose={handleClose}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "25px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "25vh",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 2px",
            borderRadius: '5px'
          }}>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px'}}>
              <CloseIcon onClick={handleClose} sx={{ color: "whitesmoke", cursor: 'pointer' }} />
            </div>
            <Typography variant="h5" textAlign='center' color='whitesmoke'>You need to upgrade your plan to share or <br /> download the video.</Typography>
            <NavLink to='/upgrade'>
              <Button variant='outlined'>Upgrade now</Button>
            </NavLink>
          </div>
        </Modal>
      </div>
    );
  }

  const QrModal = ({videoLink}) => {
    const handleClose = () => {
      setQRVideoModal(false);
    };
  
    return (
      <div>
        <Modal open={QRVideoModal} onClose={handleClose}>
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "linear-gradient(97.09deg, rgba(6, 11, 40, 0.94) 100%, rgba(10, 14, 35, 0.49) 76.65%)",
            color: "#090d2c",
            outline: "none",
            padding: "25px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "25vh",
            boxShadow: "rgb(54, 68, 97) 0px 0px 10px 2px",
            borderRadius: '5px'
          }}>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px'}}>
              <CloseIcon onClick={handleClose} sx={{ color: "whitesmoke", cursor: 'pointer' }} />
            </div>
            <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
              {/* <QRCode value={videoLink} size={200} /> */}
              <img src={`https://api.qrserver.com/v1/create-qr-code/?data=${videoLink}&amp;size=200x200`} alt={videoLink} />
            </div>
            <Button variant='outlined' onClick={()=>handleCopy(videoLink)}>{copy ? 'Copied!' : 'Copy Link'}</Button>
            <Typography variant="h5" textAlign='center'>Scan this QR code to share and download the video.</Typography>
          </div>
        </Modal>
      </div>
    );
  }

  function BasicMenu({video_id, wishlist, share}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const deleteVideo =  (video_id) => {
      setShowModal(true);
      setDeleteVideoId(video_id);
      handleClose();
      mixpanel.track('Video deleted', {
        'Video Id': video_id,
      });
    };

    const handleShare = (share) => {
      setVideoLink(share);
      // if(plan === 'Trial') {
      //   setUpgradeModal(true);
      // } else {
      //   setQRVideoModal(true);
      // }
      setQRVideoModal(true);
      mixpanel.track('Video shared', {
        'Share link': share,
      });
    }

    const handleDownload = async (videoUrl) => {
      // if(plan === 'Trial') {
      //   setUpgradeModal(true);
      // } else {
      //   const link = document.createElement('a');
      //   link.href = videoUrl;
        
      //   const fileName = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);
        
      //   link.download = fileName;
      //   link.click();
      // }
      const link = document.createElement('a');
      link.href = videoUrl;
      
      const fileName = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);
      
      link.download = fileName;
      link.click();
      mixpanel.track('Video downloaded', {
        'Download link': videoUrl,
      });
    }

    return (
      <div>
        <IoMdMore
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "30px",
            color: "lightgray",
            cursor: "pointer"
          }}
        />

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={()=>{handleWishlist(video_id, wishlist, findTrueValues); handleClose();}}>
            {wishlist ? <BookmarkIcon style={{ marginRight: 5 }} /> : <BookmarkBorderIcon style={{ marginRight: 5 }} />}
            {wishlist ? "Favourite Added" : "Favourite"}
          </MenuItem>
          <MenuItem onClick={()=>handleToggleEdit(video_id)}><DriveFileRenameOutlineIcon sx={{marginRight: '5px'}} />Rename</MenuItem>
          <MenuItem onClick={()=>handleShare(share)}><ShareIcon sx={{marginRight: '5px'}} />Share </MenuItem>
          <MenuItem onClick={()=>deleteVideo(video_id)}><DeleteOutlineIcon sx={{marginRight: '5px'}} />Delete</MenuItem>
          <MenuItem onClick={()=>handleDownload(share)}><DownloadIcon sx={{marginRight: '5px'}} />Download</MenuItem>
        </Menu>
      </div>
    );
  }
  
  function DraftMenu(id) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const deleteVideo =  async (video_id) => {
      console.log(video_id);
      
      const updatedDrafts = drafts.filter((draft) => draft.id !== video_id.video_id);
      setDrafts(dispatch, updatedDrafts);
      localStorage.setItem('drafts', JSON.stringify(updatedDrafts));
      handleClose();
      mixpanel.track('Draft deleted', {
        'Video Id': video_id,
      });
      try {
        const response = await axios.delete(`${BASE_URL}/user_drafts`, {
          params: {
            user_id,
            draft_id: video_id.draft_id,
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div>
        <IoMdMore
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "30px",
            color: "lightgray",
            cursor: "pointer"
          }}
        />

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={()=>deleteVideo(id)}><DeleteOutlineIcon sx={{marginRight: '5px'}} />Delete</MenuItem>
        </Menu>
      </div>
    );
  }
  
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Wishlist</MenuItem>
      <MenuItem onClick={close}>Edit</MenuItem>
      <MenuItem onClick={close}>Delete</MenuItem>
    </Menu>
  );

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          {currentlyPlaying ? (
            <VuiBox width="100%">
              <VideoPopup
                video_data={currentlyPlaying}
                handleClose={handleVideoClosed}
              />
            </VuiBox>
          ) : (
            <VuiBox mt="0" >
              <Grid container>
                <Grid item xs={9}>
                  {(videoOpen.Presenter || videoOpen.Editor || videoOpen.Saas || videoOpen.Draft )&& <ArrowBackIcon color="white" onClick={handleCloseVideoOpen} sx={{cursor: 'pointer'}} />}
                  {!(videoOpen.Presenter || videoOpen.Editor || videoOpen.Saas || videoOpen.Draft ) && 
                    <VuiBox>
                      <VuiTypography variant="lg" color="white" fontWeight="bold">
                        Video Library
                      </VuiTypography>
                    </VuiBox>
                  }
                  <VuiTypography variant="sm" color="white" fontWeight="light" m={2}>
                    {videoOpen.Presenter ? "Presenters Videos" : videoOpen.Editor ? "Editor Videos" : videoOpen.Saas ? "Saas Videos" : videoOpen.Draft ? "All Projects" : ""}
                  </VuiTypography>
                  {/* <VuiBox mb={1}>
                    <VuiTypography variant="lg" color="white" fontWeight="bold">
                      Video Library
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox mb="40px">
                    <VuiTypography
                      fontSize={16}
                      color="text"
                      fontWeight="regular"
                    >
                      All your videos will show up here.
                    </VuiTypography>
                  </VuiBox> */}
                </Grid>
                {(videoOpen.Presenter || videoOpen.Editor || videoOpen.Saas) && (
                  <Grid item xs={3}>
                    <VuiInput 
                      icon={{
                      component: <IoSearch />,
                      direction: "left",
                    }}
                    placeholder='search video'  
                    sx={{width: window.innerWidth <= 800 ? '100px !important' : '300px !important'}}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                </Grid>
                )}
              </Grid>
              <VuiBox>       
                {loading ? (
                  <Card sx={{width: '268px', height: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress size={50} color="white" />
                  </Card>
                ) : (
                  <VuiBox sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    {!anyVideoOpen && videoType.map((type, index) => (
                      <VuiBox key={index} sx={{margin: '10px'}} onClick={()=>handleVideoType(type)}>
                        <Card sx={{cursor: "pointer", width: '250px', height: '180px'}}>
                          <img src={folderIcon} alt="folder" width='100%' />
                        </Card>
                        <Card
                          style={{
                            zIndex: "1",
                            marginTop: "-30px",
                            borderRadius: "0 0 20px 20px ",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: '250px',
                            cursor: 'pointer',
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <VuiTypography variant="body2" color="light">
                              {type}
                            </VuiTypography>
                          </div>
                        </Card>
                      </VuiBox>
                    ))}
                    {/* {anyVideoOpen && <ArrowBackIcon color="white" onClick={handleCloseVideoOpen} sx={{cursor: 'pointer', marginBottom: '10px', position: 'absolute', top: '43%'}} />} */}
                    {videoOpen.Presenter && <Grid container spacing={2} justifyContent='flex-start'>
                       
                      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ArrowBackIcon color="white" onClick={handleCloseVideoOpen} sx={{cursor: 'pointer'}} />
                      </Grid> */}
                      {postLoading && <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '260px', border: '1px dashed gray', height: '190px'}}>
                          <CircularProgress size={30} color="white"/>
                          <Typography variant="h6" textAlign='center'>Your newly generated video will be available soon</Typography>
                        </Card>
                      </Grid>}
                      {displayedVideos.map((v_data, index) => {
                        return (
                          <Grid item xs={6} sm={6} md={4} lg={3} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} key={v_data.video_id}>
                            <Card
                              onClick={() => setCurrentlyPlaying(v_data)}
                              sx={{cursor: "pointer",
                                minWidth: '160px',
                                maxWidth: '268px',
                                minHeight: '160px',
                                maxHeight: '180px',
                                height: '160px',
                                position: 'relative'
                              }}
                            >
                              <video muted>
                                <source src={v_data.file} type="video/mp4" />
                              </video>
                              <button
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  background: 'rgba(255, 255, 255, 0.5)',
                                  border: 'none',
                                  cursor: 'pointer',
                                  borderRadius: '50%',
                                  padding: '8px',
                                  backdropFilter: 'blur(5px)',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <GiPlayButton style={{fontSize: '20px', margin: 'auto'}} />
                              </button>
                            </Card>
                            <Card
                              style={{
                                zIndex: "1",
                                marginTop: "-30px",
                                borderRadius: "0 0 20px 20px ",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // width: window.innerWidth < 480 ? '160px' : '268px',
                                minWidth: '160px',
                                maxWidth: '268px',
                                height: '60px',
                                minHeight: '50px',
                                maxHeight: '100px'
                              }}
                            >
                              {editInput && editingVideoId === v_data.video_id ? (
                                <>
                                  <input
                                    type="text"
                                    placeholder={v_data.filename}
                                    value={newFilename}
                                    onChange={(e) => setNewFilename(e.target.value)}
                                    style={{width: '120px', height: "30px", outline: "none", border: "1px solid white", borderRadius: "5px", background: "rgb(53, 73, 169)", color: "whitesmoke"}}
                                  />
                                  <IconButton
                                    onClick={() => handleSaveFilename(v_data.video_id, findTrueValues)}
                                  >
                                    {iconLoading ? (
                                      <div>
                                        <CircularProgress size={16} color="white" />
                                      </div>
                                    ) : (
                                      <Icon style={{color: "white"}}>save</Icon> 
                                    )}
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    <VuiTypography variant="body2" color="light">
                                      {v_data.filename !== null && v_data.filename.length > 15
                                        ? v_data.filename.slice(0, 15) + "..."
                                        : v_data.filename}
                                    </VuiTypography>
                                  </div>
                                </>
                              )}
                              <BasicMenu
                                video_id={v_data.video_id}
                                wishlist={v_data.wishlist}
                                share={v_data.file}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "auto",
                                  fontSize: "30px",
                                  color: "lightgray",
                                }}
                              ></BasicMenu>
                            </Card>
                          </Grid>
                        );
                      })}
                      <Grid item xs={6} sm={6} md={6} lg={3}>
                        <PlaceholderCard
                          title={{ variant: "h6", text: "Add New" }}
                          // onClick={handleClickOpen}
                          onClick={() => history.push('/create')}
                        />
                      </Grid>
                    </Grid>
                  }
                  {videoOpen.Editor && <Grid container spacing={2} justifyContent='flex-start'>
                      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ArrowBackIcon color="white" onClick={handleCloseVideoOpen} sx={{cursor: 'pointer'}} />
                      </Grid> */}
                      {postLoading && <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '260px', border: '1px dashed gray', height: '190px'}}>
                          <CircularProgress size={30} color="white"/>
                          <Typography variant="h6" textAlign='center'>Your newly generated video will be available soon</Typography>
                        </Card>
                      </Grid>}
                      {editorVideos.map((v_data, index) => {
                        return (
                          <Grid item xs={6} sm={6} md={4} lg={3} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} key={v_data.id}>   
                            <Card
                              onClick={() => setCurrentlyPlaying(v_data)}
                              sx={{cursor: "pointer",
                                minWidth: '160px',
                                maxWidth: '268px',
                                minHeight: '160px',
                                maxHeight: '180px',
                                height: '160px',
                                position: 'relative'
                              }}
                            >
                              {/* <ReactPlayer url={v_data.file} muted width="100%" height="100%" playing={isPlaying} loaded /> */}
                              <video muted>
                                <source src={v_data.video_url} type="video/mp4" />
                              </video>
                              <button
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  background: 'rgba(255, 255, 255, 0.5)',
                                  border: 'none',
                                  cursor: 'pointer',
                                  borderRadius: '50%',
                                  padding: '8px',
                                  backdropFilter: 'blur(5px)',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <GiPlayButton style={{fontSize: '20px', margin: 'auto'}} />
                              </button>
                            </Card>
                            <Card
                              style={{
                                zIndex: "1",
                                marginTop: "-30px",
                                borderRadius: "0 0 20px 20px ",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // width: window.innerWidth < 480 ? '160px' : '268px',
                                minWidth: '160px',
                                maxWidth: '268px',
                                height: '60px',
                                minHeight: '50px',
                                maxHeight: '100px'
                              }}
                            >
                              {editInput && editingVideoId === v_data.id ? (
                                <>
                                  <input
                                    type="text"
                                    placeholder={v_data.video_name}
                                    value={newFilename}
                                    onChange={(e) => setNewFilename(e.target.value)}
                                    style={{width: '120px', height: "30px", outline: "none", border: "1px solid white", borderRadius: "5px", background: "rgb(53, 73, 169)", color: "whitesmoke"}}
                                  />
                                  <IconButton
                                    onClick={() => handleSaveFilename(v_data.id, findTrueValues)}
                                  >
                                    {iconLoading ? (
                                      <div>
                                        <CircularProgress size={16} color="white" />
                                      </div>
                                    ) : (
                                      <Icon style={{color: "white"}}>save</Icon> 
                                    )}
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    <VuiTypography variant="body2" color="light">
                                      {v_data.video_name !== undefined && v_data.video_name.length > 15
                                        ? v_data.video_name.slice(0, 15) + "..."
                                        : v_data.video_name}
                                    </VuiTypography>
                                  </div>
                                </>
                              )}
                              <BasicMenu
                                video_id={v_data.id}
                                share={v_data.video_url}
                                wishlist={v_data.wishlist}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "auto",
                                  fontSize: "30px",
                                  color: "lightgray",
                                }}
                              ></BasicMenu>
                            </Card>
                          </Grid>
                        );
                      })}
                      <Grid item xs={6} sm={6} md={6} lg={3}>
                        <PlaceholderCard
                          title={{ variant: "h6", text: "Add New" }}
                          // onClick={handleClickOpen}
                          onClick={() => history.push('/videoeditor')}
                        />
                      </Grid>
                    </Grid>
                  }
                  {videoOpen.Saas && <Grid container spacing={2} justifyContent='flex-start'>
                      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ArrowBackIcon color="white" onClick={handleCloseVideoOpen} sx={{cursor: 'pointer'}} />
                      </Grid> */}
                      {postLoading && <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '260px', border: '1px dashed gray', height: '190px'}}>
                          <CircularProgress size={30} color="white"/>
                          <Typography variant="h6" textAlign='center'>Your newly generated video will be available soon</Typography>
                        </Card>
                      </Grid>}
                      {saasVideos.map((v_data, index) => {
                        return (
                          <Grid item xs={6} sm={6} md={4} lg={3} onMouseEnter={() => setIsPlaying(true)} onMouseLeave={() => setIsPlaying(false)} key={v_data.id}>   
                            <Card
                              onClick={() => setCurrentlyPlaying(v_data)}
                              sx={{cursor: "pointer",
                                minWidth: '160px',
                                maxWidth: '268px',
                                minHeight: '160px',
                                maxHeight: '180px',
                                height: '160px',
                                position: 'relative'
                              }}
                            >
                              {/* <ReactPlayer url={v_data.file} muted width="100%" height="100%" playing={isPlaying} loaded /> */}
                              <video muted>
                                <source src={v_data.video_url} type="video/mp4" />
                              </video>
                              <button
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  background: 'rgba(255, 255, 255, 0.5)',
                                  border: 'none',
                                  cursor: 'pointer',
                                  borderRadius: '50%',
                                  padding: '8px',
                                  backdropFilter: 'blur(5px)',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <GiPlayButton style={{fontSize: '20px', margin: 'auto'}} />
                              </button>
                            </Card>
                            <Card
                              style={{
                                zIndex: "1",
                                marginTop: "-30px",
                                borderRadius: "0 0 20px 20px ",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // width: window.innerWidth < 480 ? '160px' : '268px',
                                minWidth: '160px',
                                maxWidth: '268px',
                                height: '60px',
                                minHeight: '50px',
                                maxHeight: '100px'
                              }}
                            >
                              {editInput && editingVideoId === v_data.id ? (
                                <>
                                  <input
                                    type="text"
                                    placeholder={v_data.video_name}
                                    value={newFilename}
                                    onChange={(e) => setNewFilename(e.target.value)}
                                    style={{width: '120px', height: "30px", outline: "none", border: "1px solid white", borderRadius: "5px", background: "rgb(53, 73, 169)", color: "whitesmoke"}}
                                  />
                                  <IconButton
                                    onClick={() => handleSaveFilename(v_data.id, findTrueValues)}
                                  >
                                    {iconLoading ? (
                                      <div>
                                        <CircularProgress size={16} color="white" />
                                      </div>
                                    ) : (
                                      <Icon style={{color: "white"}}>save</Icon> 
                                    )}
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: "1",
                                    }}
                                  >
                                    <VuiTypography variant="body2" color="light">
                                      {v_data.video_name !== undefined && v_data.video_name.length > 15
                                        ? v_data.video_name.slice(0, 15) + "..."
                                        : v_data.video_name}
                                    </VuiTypography>
                                  </div>
                                </>
                              )}
                              <BasicMenu
                                video_id={v_data.id}
                                share={v_data.video_url}
                                wishlist={v_data.wishlist}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "auto",
                                  fontSize: "30px",
                                  color: "lightgray",
                                }}
                              ></BasicMenu>
                            </Card>
                          </Grid>
                        );
                      })}
                      <Grid item xs={6} sm={6} md={6} lg={3}>
                        <PlaceholderCard
                          title={{ variant: "h6", text: "Add New" }}
                          // onClick={handleClickOpen}
                          onClick={() => history.push('/videoeditor')}
                        />
                      </Grid>
                    </Grid>
                  }
                  {videoOpen.Draft && <Grid container spacing={2} justifyContent='flex-start' marginTop='-5px'>
                      {drafts.map((v_data, index) => {
                        return (
                          <Grid item xs={6} sm={6} md={4} lg={3} key={v_data.id}>   
                            <Card
                              onClick={() => history.push('/videoeditor', {draftId: v_data.id})}
                              sx={{cursor: "pointer",
                                minWidth: '160px',
                                maxWidth: '268px',
                                minHeight: '160px',
                                maxHeight: '180px',
                                height: '160px',
                                position: 'relative'
                              }}
                            >
                              <img src={v_data.content[0].snapshot} alt="thumbnail" />
                            </Card>
                            <Card
                              style={{
                                zIndex: "1",
                                marginTop: "-30px",
                                borderRadius: "0 0 20px 20px ",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // width: window.innerWidth < 480 ? '160px' : '268px',
                                minWidth: '160px',
                                maxWidth: '268px',
                                height: '100px',
                                minHeight: '100px',
                                maxHeight: '150px'
                              }}
                            >
                              {editInput && editingVideoId === v_data.id ? (
                                <>
                                  <input
                                    type="text"
                                    placeholder={v_data.name ? v_data.name : 'untitled'}
                                    value={newFilename}
                                    onChange={(e) => setNewFilename(e.target.value)}
                                    style={{width: '120px', height: "30px", outline: "none", border: "1px solid white", borderRadius: "5px", background: "rgb(53, 73, 169)", color: "whitesmoke"}}
                                  />
                                  <IconButton
                                    onClick={() => handleSaveFilename(v_data.id, findTrueValues)}
                                  >
                                    {iconLoading ? (
                                      <div>
                                        <CircularProgress size={16} color="white" />
                                      </div>
                                    ) : (
                                      <Icon style={{color: "white"}}>save</Icon> 
                                    )}
                                  </IconButton>
                                </>
                              ) : (
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                  <VuiTypography variant="h5" color="light">
                                    {v_data.name !== undefined && v_data.name.length > 15
                                      ? v_data.name.slice(0, 15) + "..."
                                      : v_data.name}
                                  </VuiTypography>
                                  <VuiTypography sx={{fontSize: '14px', color: 'lightgray'}}>
                                    {v_data.lastSaved}
                                  </VuiTypography>
                                  <VuiTypography variant='h6'>
                                    {v_data.type}
                                  </VuiTypography>
                                </div>
                              )}
                              <DraftMenu
                                video_id={v_data.id}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "auto",
                                  fontSize: "30px",
                                  color: "lightgray",
                                }}
                              ></DraftMenu>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  }
                  </VuiBox>
                )}
              </VuiBox>
              {allVideos.length > 11 && videoOpen.Presenter && <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(allVideos.length / itemsPerPage)}
                onPageChange={handlePageChange}
              />}
              {allEditorVideos.length > 11 && videoOpen.Editor && <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(allEditorVideos.length / itemsPerPage)}
                onPageChange={handlePageChange}
              />}
              {allSaasVideos.length > 11 && videoOpen.Saas && <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(allSaasVideos.length / itemsPerPage)}
                onPageChange={handlePageChange}
              />}
            </VuiBox>
          )}
        </Grid>
        {showModal && <DeleteModal video_id={deleteVideoId} />}
        {QRVideoModal && <QrModal videoLink={videoLink} />}
        {upgradeModal && <UpgradeModal />}
      </Grid>
      <CreateNewProject
        open={newProject}
        onClose={handleClose}
        selectedValue="username@gmail.com"
      />
    </DashboardLayout>
  );
}

export default AllVideos;
