import { createContext, useContext, useEffect, useReducer } from "react";
import BASE_URL from "./api";
import forge from 'node-forge';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import axios from "axios";
import { enqueueSnackbar, useSnackbar } from "notistack";
import mixpanel from "mixpanel-browser";

const VisionUI = createContext();

VisionUI.displayName = "VisionUIContext";

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "IS_TOUR_OPEN": {
      return { ...state, isTourOpen: action.value };
    }
    case "SET_PLAN": {
      return {...state, plan: action.value};
    }
    case "SET_NOTIFICATION": {
      return { ...state, notifications: action.value };
    }
    case "SET_WIDGET": {
      return {...state, widget: action.value};
    }
    case "SET_VIDEOAVATARS": {
      return {...state, videoAvatars: [action.value]};
    }
    case "SET_VIDEOAVATARS_LOADING": {
      return {...state, videoAvatarsLoading: action.value};
    }
    case "SET_BOTAIAVATAR": {
      return {...state, botAiAvatar: action.value};
    }
    case "SET_OPENPRICINGMODAL": {
      return {...state, openPricingModal: action.value}
    }
    case "SET_SELECTED_IMAGE_NAME": {
      return {...state, selectedImageName: action.value}
    }
    case "SET_PRESENTER_ID": {
      return {...state, presenterId: action.value}
    }
    case "SET_PREAVATARS": {
      return {...state, preAvatars: action.value}
    }
    case "SET_ALL_VIDEOS": {
      return {...state, allVideos: action.value}
    }
    case "SET_ALL_EDITOR_VIDEOS": {
      return {...state, allEditorVideos: action.value}
    }
    case "SET_ALL_SAAS_VIDEOS": {
      return {...state, allSaasVideos: action.value}
    }
    case "SET_SUBS_LOGS": {
      return {...state, subslogs: action.value}
    }
    case "SET_ALL_LOGS": {
      return {...state, allLogs: action.value}
    }
    case "SET_DRAFTS": {
      return {...state, drafts: action.value}
    }
    case "SET_FETCH_LOGS": {
      return {...state, fetchLogs: action.value}
    }
    case "SET_SUPPORT_MODAL": {
      return {...state, supportModal: action.value}
    }
    case "SET_IMAGE": {
      return {...state, image: action.value}
    }
    case "SET_LOADING": {
      return{...state , loading : action.value};
    }
    case "SET_POST_LOADING": {
      return{...state , postLoading : action.value};
    }
    case "IS_LOGGED_IN": {
      return { ...state, isLoggedIn: action.value };
    }
    case "OPEN_SIDE_MENU": {
      return { ...state, openSideMenu: action.value };
    }
    case "AUTH_LOADING": {
      return { ...state, authLoading: true, authError: null };
    }
    case "SIGNUP_SUCCESS": {
      return { ...state, authLoading: false };
    }
    case "SIGNUP_ERROR": {
      return { ...state, authLoading: false, authError: action.error };
    }
    case "LOGIN_SUCCESS": {
      const { email_id, first_name, user_id, plan, total_credits, org_info, mobile, access_token } = action.payload.user;
      let planName = plan.split(' ');
      const updatedUserDetails = {
        email_id,
        first_name,
        user_id,
        plan: planName[1],
        // plan,
        total_credits,
        org_info,
        mobile,
        access_token
      };
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("userDetails", JSON.stringify(updatedUserDetails));

      if(total_credits < 5 && plan === 'Trial'){
        setTimeout(() => {
          enqueueSnackbar('To continue creating amazingly professional videos, upgrade now.', { 
            variant: 'warning',
            autoHideDuration: 10000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }, 10000);
      }
      
      if(total_credits < 5 && plan !== 'Trial'){
        setTimeout(() => {
          enqueueSnackbar(`There are only ${total_credits} credits left in your wallet. \n Upgrade now to continue using all features.`, { 
            variant: 'warning',
            autoHideDuration: 10000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }, 10000);
      }

      return { ...state, authLoading: false, isLoggedIn: true, userDetails: updatedUserDetails
      };
    }
    case "LOGIN_ERROR": {
      return { ...state, authLoading: false, authError: action.error };
    }
    case "LOGOUT": {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userDetails");
      localStorage.removeItem('agents');
      return { ...state, authLoading: false, isLoggedIn: false, userDetails: {
        email_id: "",
        first_name: "",
      }, };
    }
    case "COMPANY_INFO": {
      return { ...state, companyInfoLoading: true, companyInfoError: null };
    }
    case "COMPANY_INFO_SUCCESS": {
      const { user_id, ...companyInfoData } = action.payload;
      return {
        ...state,
        companyInfoLoading: false,
        company_info: {
          ...companyInfoData,
          user_id: state.userDetails.user_id,
        },
        companyInfoError: null,
      };
    }
    case "COMPANY_INFO_ERROR": {
      return {
        ...state,
        companyInfoLoading: false,
        companyInfoError: action.error,
      };
    }
    case "SET_AGENTS_CREATED": {
      return {
        ...state, 
        agentsCreated: action.value
      }
    }
    case "SET_SESSION_ACTIVE": {
      return {
        ...state, 
        sessionActive: action.value
      }
    }
    case "SET_BOT_DETAILS": {
      return {
        ...state, 
        botDetails: action.value
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function VisionUIControllerProvider({ children }) {
  const storedUserDetails = localStorage.getItem("userDetails");
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   const isTokenExpired = (token) => {
  //     try {
  //       const decoded = jwt.decode(token);
  //       if (decoded && decoded.exp) {
  //         const currentTime = Math.floor(Date.now() / 1000);
  //         return decoded.exp < currentTime;
  //       }
  //     } catch (error) {
  //       console.error('Error decoding JWT:', error);
  //     }
  //     return true; 
  //   };

  //   const initiateSessionLogout = () => {
  //     localStorage.removeItem("isLoggedIn");
  //     localStorage.removeItem("userDetails");
  //     dispatch({ type: "LOGOUT" });
  //   };

  //   const checkTokenExpiration = () => {
  //     const userDetails = localStorage.getItem("userDetails");
  //     if (userDetails) {
  //       const { access_token } = JSON.parse(userDetails);
  //       const res = isTokenExpired(access_token);
  //       console.log(res);
  //       if (isTokenExpired(access_token)) {
  //         initiateSessionLogout();
  //       }
  //     }
  //   };

  //   checkTokenExpiration();
  //   const tokenCheckInterval = setInterval(checkTokenExpiration, 30000);

  //   return () => {
  //     clearInterval(tokenCheckInterval);
  //   };
  // }, []);

  // const initialDrafts = [
  //   { type: 'video editor', content: null },
  //   { type: 'presenter', content: null },
  //   { type: 'saas', content: null },
  // ];

  const initialState = {
    miniSidenav: true,
    transparentSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    isTourOpen: false,
    openSideMenu: false,
    isLoggedIn: localStorage.getItem("isLoggedIn") === "true" || false,
    authLoading: false,
    authError: null,
    plan: '',
    userDetails: storedUserDetails ? JSON.parse(storedUserDetails) : { email_id: "", first_name: "", user_id: "" },
    company_info: {
      value: "",
      size: "",
      role: "",
      user_id: "",
    },
    notifications: null,
    widget: false,
    videoAvatars: [],
    videoAvatarsLoading: false,
    botAiAvatar: 'https://quickvideo.blob.core.windows.net/quickvideo/presenters/preview/50bafc.mp4',
    loading: false,
    postLoading: false,
    openPricingModal: false,
    selectedImageName: 0,
    presenterId: "rian-pbMoTzs7an",
    agentsCreated: [],
    image: "https://quickvideo.blob.core.windows.net/quickvideo/did_avatars/D.png",
    sessionActive: false,
    botDetails: {
      name: '',
      companyName: '',
      wMessage: ''
    },
    preAvatars: [],
    allVideos: [],
    allEditorVideos: [],
    allSaasVideos: [],
    supportModal: false,
    subslogs: [],
    allLogs: [],
    drafts: [],
    fetchLogs: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const { userDetails, isLoggedIn, agentsCreated, postLoading, fetchLogs, drafts } = controller;

  useEffect(() => {
    const savedDrafts = localStorage.getItem('drafts');
    
    if (!savedDrafts) {
      localStorage.setItem('drafts', JSON.stringify(drafts));
    } else {
      setDrafts(dispatch, JSON.parse(savedDrafts))
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('drafts', JSON.stringify(drafts));
  }, [drafts]);

  const checkInternetSpeed = () => {
    if (navigator.connection) {
      const speedInMbps = navigator.connection.downlink;

      if (speedInMbps < 1) {
        enqueueSnackbar('Your internet is unstable, it may affect your work loads.', { 
          variant: 'info',
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } else {
      console.log("Network Information API is not supported in this browser.");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkInternetSpeed, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
      isLoggedIn && axios.get(`${BASE_URL}/presenters`, {
        params: {
          user_id: userDetails.user_id
        },
      })
      .then(response => {
        const newAvatars = response.data.presenters;
        setPreAvatars(dispatch, newAvatars);
        setVideoAvatars(dispatch, newAvatars);
      })
      .catch(error => console.error(error));
  }, [isLoggedIn]);

  useEffect(() => {
    const storedAgents = localStorage.getItem('agents');
    const getChatbotInfo = async () => {
      if(agentsCreated.length > 0 && storedAgents) {
        return;
      } 
      
      const headers = {
        accept: 'application/json',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${userDetails.user_id}`,
      };
      
      const options = {
        method: 'GET',
        url: `${BASE_URL}/v1/chatbot_info`,
        headers: headers,
      };
      
      await axios(options)
        .then(response => {
          setAgentsCreated(dispatch, response.data.widgetDetails);
          localStorage.setItem('agents', response.data.widgetDetails);
        })
        .catch(error => {
          console.error(error);
        });
    }

    isLoggedIn && getChatbotInfo();
  }, [isLoggedIn]);

  const fetchData = async () => {
    const userId = userDetails.user_id;
    try {
      const response = await axios.get(`${BASE_URL}/ai_videos`, {
        headers: {
          'Authorization': `Bearer ${userDetails.access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllVideos(dispatch, response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  
  const fetchEditorData = async () => {
    const userId = userDetails.user_id;
    try {
      const response = await axios.get(`${BASE_URL}/video_details`, {
        headers: {
          'Authorization': `Bearer ${userDetails.access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllEditorVideos(dispatch, response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } 
  };
  
  const fetchSaasData = async () => {
    const userId = userDetails.user_id;
    try {
      const response = await axios.get(`${BASE_URL}/saas_video`, {
        headers: {
          'Authorization': `Bearer ${userDetails.access_token}`,
        },
        params: {
          user_id: userId,
        },
      });
      setAllSaasVideos(dispatch, response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(error.message, {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } 
  };

  const subscriptionLogs = async () => {
    try {
      axios.get(`${BASE_URL}/sublog`, {
        params: {
          user_id: userDetails.user_id
        },
      })
      .then((res) => {
        setSubslogs(dispatch, res.data);
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar(err.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      })
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllLogs = async () => {
    axios.get(`${BASE_URL}/logs_info`, {
      params: {
        user_id: userDetails.user_id
      },
    })
    .then((res) => {
      setAllLogs(dispatch, res.data);
    })
    .catch((err) => console.log(err))
  };

  useEffect(() => {
    fetchLogs && fetchAllLogs();
    setFetchLogs(dispatch, false);
    // eslint-disable-next-line
  }, [fetchLogs]);

  useEffect(() => {
    isLoggedIn && fetchData();
    isLoggedIn && fetchEditorData();
    isLoggedIn && fetchSaasData();
    isLoggedIn && subscriptionLogs();
    isLoggedIn && fetchAllLogs();
    // eslint-disable-next-line
  }, [isLoggedIn, postLoading]);

  useEffect(() => {
    isLoggedIn && axios.get(`${BASE_URL}/notifications`, {
      params: {
        user_id: userDetails.user_id
      }
    })
    .then((res) => {
      setNotification(dispatch, res.data.notifications);
    })
    .catch((err)=>{
      console.log(err);
    })
    //eslint-disable-next-line
  }, [isLoggedIn]);

  // useEffect(()=> {
  //   const roomName = userDetails.user_id;
  //   const socket = new WebSocket(`wss://bac.quickvideo.ai/ws/notification/${roomName}/`);

  //   isLoggedIn ? socket.onopen = () => {
  //     console.log('WebSocket connection established.');
  //   } : socket.onclose = () => console.log('cant established');

  //   socket.onmessage = (event) => {
  //     try {
  //       const message = JSON.parse(event.data);
  //       setNotification(dispatch, message);
  //       enqueueSnackbar(message.data, { 
  //         variant: message.color,
  //         autoHideDuration: 5000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //       });
  
  //       // if (message.event === 'new_message') {
  //       //   setNotification(dispatch, message);
  //       //   enqueueSnackbar(message.data, { 
  //       //     variant: "info",
  //       //     autoHideDuration: 3000,
  //       //     anchorOrigin: {
  //       //       vertical: "top",
  //       //       horizontal: "right",
  //       //     },
  //       //   });
  //       // }
  //     } catch (error) {
  //       console.error('Error parsing incoming message as JSON:', error);
  //     }
  //   };

  //   socket.addEventListener('error', (event) => {
  //     console.log('WebSocket error:', event);
  //   });

  //   return () => {
  //     socket.close();
  //   };
  //   //eslint-disable-next-line
  // }, [isLoggedIn])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userDetails } = controller;
        axios.get(`${BASE_URL}/subscribe`, {
          headers: {
            'Authorization': `Bearer ${userDetails.access_token}`,
          },
          params: {
            user_id: userDetails.user_id
          },
        })
        .then((res) => {
          let planName = res.data.plan.name.split(' ');
          setPlanName(dispatch, planName[1])
        })
        .catch(err => {
          console.log(err);
        })
      } catch (error) {
        console.log(error);
      }
    };

    isLoggedIn && fetchData(); 
    //eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <VisionUI.Provider value={[controller, dispatch]}>
      {children}
    </VisionUI.Provider>
  );
}

// Vision UI Dashboard PRO React custom hook for using context
function useVisionUIController() {
  const context = useContext(VisionUI);

  if (!context) {
    throw new Error(
      "useVisionUIController should be used inside the VisionUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the VisionUIControllerProvider
VisionUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setIsTourOpen = (dispatch, value) =>
  dispatch({ type: "IS_TOUR_OPEN", value });
const setPlanName = (dispatch, value) => 
  dispatch({type: "SET_PLAN", value});
const setNotification = (dispatch, value) => 
  dispatch({type:"SET_NOTIFICATION", value})
const setWidget = (dispatch, value) => 
  dispatch({type: "SET_WIDGET", value})
const setVideoAvatars = (dispatch, value) => 
  dispatch({type: "SET_VIDEOAVATARS", value})
const setVideoAvatarsLoading = (dispatch, value) =>
  dispatch({type: "SET_VIDEOAVATARS_LOADING", value})
const setBotAiAvatar = (dispatch, value) =>
  dispatch({type: "SET_BOTAIAVATAR", value})
const setOpenPricingModal = (dispatch, value) => 
  dispatch({type: "SET_OPENPRICINGMODAL", value})
const setSelectedImageName = (dispatch, value) => 
  dispatch({type: "SET_SELECTED_IMAGE_NAME", value})
const setPresenterId = (dispatch, value) => 
  dispatch({type: "SET_PRESENTER_ID", value})
const setImage = (dispatch, value) => 
  dispatch({type: "SET_IMAGE", value})
const setLoading = (dispatch, value) => 
  dispatch({type: "SET_LOADING", value})
const setPostLoading = (dispatch, value) => 
  dispatch({type: "SET_POST_LOADING", value})
const setIsLoggedIn = (dispatch, value) =>
  dispatch({ type: "IS_LOGGED_IN", value });
const setOpenSideMenu = (dispatch, value) =>
  dispatch({ type: "OPEN_SIDE_MENU", value });
const setAuthLoading = (dispatch, value) => 
  dispatch({ type: "AUTH_LOADING", value});
const setAgentsCreated = (dispatch, value) => 
  dispatch({type: "SET_AGENTS_CREATED", value});
const setSessionActive = (dispatch, value) => 
  dispatch({type: "SET_SESSION_ACTIVE", value});
const setBotDetails = (dispatch, value) =>
  dispatch({type: "SET_BOT_DETAILS", value});
const setPreAvatars = (dispatch, value) => 
  dispatch({type: "SET_PREAVATARS", value});
const setAllVideos = (dispatch, value) => 
  dispatch({type: "SET_ALL_VIDEOS", value});
const setAllEditorVideos = (dispatch, value) => 
  dispatch({type: "SET_ALL_EDITOR_VIDEOS", value});
const setAllSaasVideos = (dispatch, value) => 
  dispatch({type: "SET_ALL_SAAS_VIDEOS", value});
const setSupportModal = (dispatch, value) => 
  dispatch({type: "SET_SUPPORT_MODAL", value});
const setSubslogs = (dispatch, value) =>
  dispatch({type: "SET_SUBS_LOGS", value});
const setAllLogs = (dispatch, value) =>
  dispatch({type: "SET_ALL_LOGS", value});
const setDrafts = (dispatch, value) =>
  dispatch({type: "SET_DRAFTS", value});
const setFetchLogs = (dispatch, value) =>
  dispatch({type: "SET_FETCH_LOGS", value});
const companyInfo = async (dispatch, value, size, role, user_id) => {
  dispatch({type: "COMPANY_INFO"});
  try {
    const res = await fetch(`${BASE_URL}/company_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({value, size, role, user_id}),
    });

    if (res.ok) {
      const data = await res.json();
      dispatch({ type: "COMPANY_INFO_SUCCESS", payload: { user_id, ...data } });
    } else {
      const data = await res.json();
      dispatch({ type: "COMPANY_INFO_ERROR", error: data.error });
    }
  } catch (error) {
    dispatch({ type: "COMPANY_INFO_ERROR", error: "An error occurred" });
  }
}
const signup = async (dispatch, userData) => {
  // dispatch({ type: "AUTH_LOADING" });

  try {
    const response = await fetch(`${BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    
    if (response.ok) {
      dispatch({ type: "SIGNUP_SUCCESS" });
      mixpanel.identify(response.id);
      mixpanel.people.set({ 
        '$name': userData.first_name,
        '$email': userData.email_id,
      });
      mixpanel.track('Signed up', {
        'name': userData.first_name,
        'email': userData.email_id,
      });
    } else {
      const data = await response.json();
      const errorMessage = data.error || "Signup failed";
      dispatch({ type: "SIGNUP_ERROR", error: data.error });
      throw new Error(errorMessage);
    }
  } catch (error) {
    dispatch({ type: "SIGNUP_ERROR", error: "An error occurred" });
    throw error;
  }
};

const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAm7bEqZULRUpCIU7yXsVJ
0qycCKbHsPnUcnGdp7jUxzeoCmwyngM+dsxB8k9nISYFrk4s+PGfLYN+qcHvbkBb
TJoNZ+RBRHMd5NbFHRVIyjvy8t5ChVFGgSM2Fpwnpb0cGPUGV/mhOd94z849fC64
2rDArMotum674pP0B6FIbnEcGYOnTy1HjTMmELrTK1nBCvoMhqqC0da0dGOcG8PC
5HDotXsfNGIb1ZSR7RMnq8iXt7gQQQCw39G1YnHwTBDLzEGN8c0q26Hlmt3wZxHr
jOM6AcpC9I7JLMHIZincJ936rW4SFQFa6F2FHv8g6QHdsngGQZ4WT/joDYLJaP5Y
QQIDAQAB
-----END PUBLIC KEY-----`;

function encryptPassword(password, publicKeyPem) {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encrypted = publicKey.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: forge.mgf.mgf1.create(forge.md.sha1.create())
  });
  return forge.util.encode64(encrypted);
}

function decryptPassword(encryptedPassword, privateKeyPem) {
  const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
  const encryptedBytes = forge.util.decode64(encryptedPassword);
  const decrypted = privateKey.decrypt(encryptedBytes, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: forge.mgf.mgf1.create(forge.md.sha1.create())
  });
  return decrypted;
}

const login = async (dispatch, userData, rememberMe) => {
  // dispatch({ type: "AUTH_LOADING" });

  try {
    const response = await fetch(`${BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      
      mixpanel.identify(data.user.user_id);
      mixpanel.people.set({ 
        '$name': data.user.first_name,
        '$email': data.user.email_id,
      });
      mixpanel.track('Logged in', {
        'name': data.user.first_name,
        'email': data.user.email_id,
        'plan': data.user.plan,
        'credits': data.user.total_credits
      });
      if (rememberMe) {
        // const encryptedPassword = encryptPassword(userData.password, publicKeyPem);
        localStorage.setItem("rememberedEmail", userData.email_id);
        localStorage.setItem("rememberedPassword", userData.password);
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }
    } else {
      const data = await response.json();
      const errorMessage = data.message || "Login failed";
      dispatch({ type: "LOGIN_ERROR", error: errorMessage });
      throw new Error(errorMessage);
    }
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error.message});
    throw error;
  }
};

const googleLogin = async (dispatch, response) => {
  try {
    const res = await axios.post('https://549ac5f7af1d2b30ef6f6fc7f8dc7d76.serveo.net/google', {
      token: response.credential,
    });

    if (res.status === 200) {
      const data = res.data;
      console.log(data);
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
    } else {
      const errorMessage = res.data.message || "Login failed";
      dispatch({ type: "LOGIN_ERROR", error: errorMessage });
      throw new Error(errorMessage);
    }
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error.message });
    throw error;
  }
}

const logout = (dispatch) => {
  dispatch({ type: "LOGOUT" });
};

export {
  VisionUIControllerProvider,
  useVisionUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setIsTourOpen,
  setPlanName,
  setIsLoggedIn,
  setOpenSideMenu,
  signup,
  login,
  encryptPassword,
  decryptPassword,
  googleLogin,
  logout,
  setAuthLoading,
  companyInfo,
  setWidget,
  setVideoAvatars,
  setVideoAvatarsLoading,
  setBotAiAvatar,
  setLoading,
  setPostLoading,
  setOpenPricingModal,
  setSelectedImageName,
  setPresenterId,
  setImage,
  setAgentsCreated,
  setSessionActive,
  setBotDetails,
  setPreAvatars,
  setAllVideos,
  setAllEditorVideos,
  setAllSaasVideos,
  setSupportModal,
  setSubslogs,
  setAllLogs,
  setFetchLogs,
  setDrafts,
};
