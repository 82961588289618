import { AppBar, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useRef, useState } from 'react';
import chatgpticon from './images/openai-chatgpt.jpeg';
import urltoaiicon from './images/ico_url_2_video.png';
import solidicon from './images/chromakey_paris.jpeg';
import defaulticon from './images/ico_default_template.png';
import templates from './templates';
import WelcomeModal from './WelcomeModal';
import EditingSection from './EditingSection';
import axios from 'axios';
import BASE_URL from '../../../context/api';
import { useVisionUIController } from "context";
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import ConfirmationModal from './confirmationModal';
import './editor.css';
import Lock from 'lock';
import { setPostLoading } from 'context';
import mixpanel from 'mixpanel-browser';
import { setFetchLogs } from 'context';
import { setDrafts } from 'context';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const VideoEditor = () => {
  const [controller, dispatch] = useVisionUIController();
  const { userDetails, drafts } = controller;
  const { plan, user_id } = userDetails;
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [template, setTemplate] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [finalApiHit, setFinalApiHit] = useState(false);
  const [createVideo, setCreateVideo] = useState(true);
  const [selectBreak, setSelectBreak] = useState('0.5s');
  const [selectSpeed, setSelectSpeed] = useState('1.0x');
  const [changeInput, setChangeInput] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [selectLanguage, setSelectLanguage] = useState('English');
  const [selectVoice, setSelectVoice] = useState('');
  const [voiceOption, setVoiceOption] = useState([]);
  const [models, setModels] = useState([]);
  const [touched, setTouched] = useState({
    template: false,
    model: true,
    text: false,
    subtitle: false,
    image: false,
    background: false,
    video: false,
    audio: false,
    shapes: false,
    animation: false,
  });

  const [alignment, setAlignment] = useState('sl');
  const [value, setValue] = useState('one');
  const [fonts, setFonts] = useState(["Times New Roman","Georgia","Garamond","Baskerville","Palatino","Arial","Helvetica","Calibri","Verdana","Futura","Courier New","Consolas","Inconsolata","Monaco","Liberation Mono","Brush Script","Lucida Calligraphy","Zapfino","Pacifico","Snell Roundhand","Comic Sans MS","Papyrus","Jokerman","Curlz MT","Chiller","Century Gothic","Avant Garde Gothic","Didot","Bodoni","Rockwell","D'Nealian","Schoolbell","Comic Neue","Quicksand","Architect's Daughter","Edwardian Script","Brush Script MT","Snell Roundhand","French Script","Palace Script MT","Impact","Stencil", "Playbill", "Bauhaus 93", "Broadway", "Rockwell", "Courier", "Egyptienne", "Clarendon", "Archer"]);
  const [selectedFont, setSelectedFont] = useState('Times New Roman');
  function fontSizeArray() {
    let arr = [];
    for (let i = 10; i <= 110; i++) {
      arr.push(i);
      i++;
    }
    return arr;
  }
  const [fontSize, setFontSize] = useState(fontSizeArray);
  const [selectedFontSize, setSelectedFontSize] = useState(12);

  const [selectedFile, setSelectedFile] = useState(null);
  const [stockImages, setStockImages] = useState([]);
  const [imageQuery, setImageQuery] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [backgroundTabValue, setBackgroundTabValue] = useState(0);

  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [stockVideos, setStockVideos] = useState([]);
  const [videoQuery, setVideoQuery] = useState('');
  const [videoPage, setVideoPage] = useState(1);
  const [loadingModal, setLoadingModal] = useState(false);
  const [uploadedAudio, setUploadedAudio] = useState(null);
  const [imageTotalPages, setImageTotalPages] = useState(null);
  const [videoTotalPages, setVideoTotalPages] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [stockShapes, setStockShapes] = useState([]);
  const [shapesQuery, setShapesQuery] = useState('default');
  const [indexToStore, setIndexToStore] = useState({
    header: -1,
    title: -1,
    main_text: -1,
    caption: -1,
  });
  const [currentDraftId, setCurrentDraftId] = useState(null);
  const [lastSaved, setLastSaved] = useState('');

  const calculateDimensions = (aspectRatio, baseHeight) => {
    let height = baseHeight;
    let width;
  
    if (aspectRatio === '4:3') {
      width = (4 / 3) * height;
    } else if (aspectRatio === '16:9') {
      width = (16 / 9) * height;
    }
  
    return { width, height };
  };

  const initialScene = { 
    snapshot: null, 
    script: '', 
    background: '#F0F0FF',
    selectedModel: null,
    selectedImage: [],
    selectedVideo: null,
    selectedShape: [],
    avatarVideo: false,
    textToggle: {
      header: false,
      title: false,
      main_text: false,
      caption: false
    },
    textArrays: {
      header: [],
      title: [],
      main_text: [],
      caption: [],
    },
    audio: {selectBreak: '0.5s', selectSpeed: '1.0x', selectLanguage: 'English', selectVoice: {value: '21m00Tcm4TlvDq8ikWAM', label: 'Rachel (female)'}},
    resolution: {
      type: '720p (16:9)',
      expectedSize: {
        width: 1280,
        height: 720
      },
      ...calculateDimensions('16:9', 360),
    },
  };

  const [openSettings, setOpenSettings] = useState({
    model: false,
    text: false,
    image: false,
    video: false,
    audio: false,
    shape: false,
  })

  const [scenes, setScenes] = useState([initialScene]);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const componentRef = useRef(null);

  const updateSceneKey = (index, key, value) => {
    setScenes(prevScenes => {
      const updatedScenes = [...prevScenes];
      updatedScenes[index][key] = value;
      return updatedScenes;
    });
  };

  const addScene = () => {
    setScenes(prevScenes => [...prevScenes, { ...initialScene }]);
    setCurrentSceneIndex(prevIndex => prevIndex + 1);
    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
    });
  };

  const deleteScene = (index) => {
    setScenes((prevScenes) => {
      const updatedScenes = [...prevScenes];
      updatedScenes.splice(index, 1);
  
      const newIndex = Math.min(index, updatedScenes.length - 1);
      setCurrentSceneIndex(newIndex);
  
      return updatedScenes;
    });
    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
    });
  };
  
  const location = useLocation();
  const { draftId } = location.state || {};
  const [isNewDraftCreated, setIsNewDraftCreated] = useState(false);
  
  const generateUniqueId = (existingDrafts) => {
    let id;
    do {
      id = parseInt(Math.random() * 100000);
    } while (existingDrafts.some((draft) => draft.id === id));
    return id;
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const updateDraftContent = (id, newContent) => {
    const updatedDrafts = drafts.map((draft) =>
      draft.id === id ? { ...draft, content: newContent, name: videoName, lastSaved: getCurrentTimestamp() } : draft
    );

    setDrafts(dispatch, updatedDrafts);
  };

  useEffect(() => {
    if (draftId) {
      const draftToLoad = drafts.find((draft) => draft.id === parseInt(draftId));
      if (draftToLoad) {
        setScenes(draftToLoad.content); 
        setCurrentDraftId(draftToLoad.id); 
        setLastSaved(draftToLoad.lastSaved);
        setVideoName(draftToLoad.name);
      }
    } else if (!isNewDraftCreated) {
      const newDraftId = generateUniqueId(drafts);
      setCurrentDraftId(newDraftId);

      const newDraft = {
        id: newDraftId,
        content: scenes,
        name: videoName,
        type: 'video editor',
        lastSaved: getCurrentTimestamp(),
      };

      const updatedDrafts = [...drafts, newDraft]; 
      setDrafts(dispatch, updatedDrafts); 
      setLastSaved(newDraft.lastSaved);
      setIsNewDraftCreated(true); 
    }
  }, [draftId, drafts, isNewDraftCreated]);
  
  const handleSave = () => {
    if (currentDraftId) {
      updateDraftContent(currentDraftId, scenes);
      enqueueSnackbar('Draft saved.', {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleSave, 10000);
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   const postData = async () => {
  //     try {
  //       await axios.post(`${BASE_URL}/user_drafts`, {
  //         drafts: drafts,
  //         user_id
  //       }).then((res) => console.log(res.data));
  //     } catch (error) {
  //       console.error('Error posting data:', error);
  //     }
  //   };

  //   const intervalId = setInterval(postData, 100000);
  //   return () => clearInterval(intervalId);
  // }, [drafts, user_id]);
  
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       await axios.get(`${BASE_URL}/user_drafts`, {
  //         params: {user_id}
  //       }).then((res) => console.log(res.data));
  //     } catch (error) {
  //       console.error('Error posting data:', error);
  //     }
  //   };

  //   const intervalId = setInterval(getData, 3000);
  //   return () => clearInterval(intervalId);
  // }, []);

  const handleBoxClick = (index) => {
    setCurrentSceneIndex(index);
    setOpenSettings({
      model: false,
      text: false,
      image: false,
      video: false,
      audio: false,
      shape: false,
    });
    // localStorage.setItem('currentSceneIndex', JSON.stringify(index));
  };

  // useEffect(() => {
  //   const storedIndex = JSON.parse(localStorage.getItem('currentSceneIndex'));
  //   if (storedIndex !== null) {
  //     setCurrentSceneIndex(storedIndex);
  //   }
  // }, []);

  const [items, setItems] = useState([]);
  
  const lastVideoRef = useRef(null);
  const imageObserver = useRef(null);
  const videoObserver = useRef(null);
  const lastImageRef = useRef(null);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/stock_images`, {
      params: {shapesQuery},
    })
    .then((res) => {
      setStockShapes(res.data.icons);
    })
    .catch((err) => console.log(err));
  }, [shapesQuery]);

  const EXCLUDE_KEYWORDS = ['nude', 'violence', 'crime', 'harmful', 'explicit', 'hip' ,'bikini', 'sex', 'sexy', 'hot', 'porn'];

  const filterByKeywords = (photo) => {
    const title = (photo.alt || '').toLowerCase();
    const photographer = (photo.photographer || '').toLowerCase();

    return !EXCLUDE_KEYWORDS.some((keyword) =>
      title.includes(keyword) ||
      photographer.includes(keyword)
    );
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        let apiUrl = `https://api.pexels.com/v1/popular?per_page=20&page=${page}`;

        if (imageQuery) {
          apiUrl = `https://api.pexels.com/v1/search?query=${imageQuery}&per_page=20&page=${page}`;
        }
        const response = await axios.get(
          apiUrl, {
            headers: {
              Authorization: 'MJYis5oZSkobDNMuOjniNnsiSjgwkpQdxOW8x3JyMm1tk6nBwdwRfeYU',
              'Content-Type': 'application/json',
            },
            params: {
              content_filter: 'high',
            },
          }
        );

        if (page > response.data.photos / 20) {
          setLoading(false);
          return;
        }

        const filteredPhotos = response.data.photos.filter(filterByKeywords);
        setStockImages((prevImages) => [...prevImages, ...filteredPhotos]);
        setImageTotalPages(Math.ceil(response.data.total / 20));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchImages();
  }, [imageQuery, page, imageObserver, lastImageRef]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        let apiUrl = `https://api.pexels.com/videos/popular?per_page=20&page=${videoPage}`;

        if (videoQuery) {
          apiUrl = `https://api.pexels.com/videos/search?query=${videoQuery}&per_page=20&page=${videoPage}`;
        }
        const response = await axios.get(
          // `https://pixabay.com/api/videos/?key=39676690-85c2818d2b6382af1f6fc467e&q=${videoQuery}&page=${videoPage}&per_page=20&safesearch=true&orientation=horizontal`
          apiUrl, {
            headers: {
              Authorization: 'MJYis5oZSkobDNMuOjniNnsiSjgwkpQdxOW8x3JyMm1tk6nBwdwRfeYU',
              'Content-Type': 'application/json',
            },
            params: {
              content_filter: 'high',
            },
          }
        );

        if (videoPage > response.data.videos / 20) {
          setLoading(false);
          return;
        }

        setStockVideos((prevVideos) => [...prevVideos, ...response.data.videos]);
        setVideoTotalPages(Math.ceil(response.data.total / 20));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, [videoQuery, videoPage]);

  useEffect(() => {
    axios.get(`${BASE_URL}/presenters`, {
      params: {
        user_id,
      }
    })
    .then((res) => setModels(res.data.presenters));
    //eslint-disable-next-line
  }, [])

  const faceModels = ["https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/1.png?updatedAt=1705384899887", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/2.png?updatedAt=1705384965522", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/3.png?updatedAt=1705384985672", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/4.png?updatedAt=1705385138960", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/5.png?updatedAt=1705385160935", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/6.png?updatedAt=1705385201046", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/7.png?updatedAt=1705385231477", "https://ik.imagekit.io/iohh3uasj9/QuickVideo/Editor%20avatars/8.png?updatedAt=1705385304072"]

  useEffect(() => {
    setWelcomeModal(true);
    setTemplate(templates);
  }, []);

  const beautyTemplate = template.filter(item => item.id === 'beauty');
  const newsTemplate = template.filter(item => item.id === 'news');
  const businessTemplate = template.filter(item => item.id === 'business');
  const salesTemplate = template.filter(item => item.id === 'sales');
  const socialTemplate = template.filter(item => item.id === 'social');
  const presentationTemplate = template.filter(item => item.id === 'presentation');
  const educationTemplate = template.filter(item => item.id === 'education');
  const eventTemplate = template.filter(item => item.id === 'event');
  const hobbyTemplate = template.filter(item => item.id === 'hobby');
  const chatTemplate = template.filter(item => item.id === 'chat');
  const history = useHistory();
  
  const handleUpgradeClick = () => {
    history.push('/upgrade');
  };

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleLeaveClick = () => {
    // const confirmLeave = window.confirm('Are you sure you want to leave this page, any changes made will be lost ?');
    handleSave();
    // if (confirmLeave) {
      // history.push("/dashboard");
    history.goBack();
    // }
  };

  const handleSubmit = () => {
    setConfirmModal(true);
  };

  const handleFinalSubmit = async () => {
    setFinalApiHit(true);
    setPostLoading(dispatch, true);
    await axios.post(`${BASE_URL}/video_edit`, {
      user_id,
      scenes,
      projectName: videoName,
    })
    .then((res) => {
      setPostLoading(dispatch, false);
      setFetchLogs(dispatch, true);
      mixpanel.track('Video Creation', {
        'video name': videoName,
        'type': 'Editor Video'
      });
    })
    .catch((err) => {
      console.log(err);
      setPostLoading(dispatch, false);
      enqueueSnackbar('Something went wrong', {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    })
  }
  
  return (
    <>
      <DashboardNavbar />
      {plan === '' && <VuiBox sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2'}}>
        <Lock height={500} line="Video Editor Feature" />
      </VuiBox>}
      {window.innerWidth < 800 && <VuiBox sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2', background: '#000000', padding: '25px', borderRadius: '20px'}}>
        <VuiTypography color='white' variant='h5' textAlign='center'>Please open desktop mode to access video editor</VuiTypography>
      </VuiBox>}
      {!createVideo && 
        <VuiBox sx={{padding: '10px 20px'}}>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
            <VuiBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <VuiTypography variant='h5' color= 'white' sx={{marginBottom: '20px'}}>Start editing with</VuiTypography>
              <Button variant='contained' sx={{color: 'whitesmoke', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100px'}} onClick={handleLeaveClick}>Leave <LogoutIcon /></Button>
            </VuiBox>
            <VuiBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}} onClick={() => setWelcomeModal(true)}>
                <Button>
                  <img src={chatgpticon} alt="icons" width={250} height={130} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                </Button>
                <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>ChatGPT</Typography>
              </VuiBox>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
                <Button>
                  <img src={urltoaiicon} alt="icons" width={250} height={130} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                </Button>
                <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>URL to AI Video</Typography>
              </VuiBox>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
                <Button>
                  <img src={solidicon} alt="icons" width={250} height={130} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                </Button>
                <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>Solid background</Typography>
              </VuiBox>
              <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
                <Button>
                  <img src={defaulticon} alt="icons" width={250} height={130} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                </Button>
                <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>Default Template</Typography>
              </VuiBox>
            </VuiBox>
          </VuiBox>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', marginTop: '50px'}}>
            <VuiTypography variant='h5' color= 'white' sx={{marginBottom: '10px'}}>Start with a template</VuiTypography>
            <Grid container alignItems={"center"}>
              <AppBar
                position="static"
                sx={{ display: "flex", mb: 0, alignItems: "center" }}
              >
                <Tabs value={tabValue} onChange={tabHandler} centered sx={{
                  '& .MuiTab-root': {
                    width: '100%',
                    minWidth: 'auto',
                    marginLeft: "10px",
                    marginRight: "10px",
                    paddingLeft: "15px !important",
                    paddingRight: "15px !important"
                  },
                  '& .MuiTabs-flexContainer': {
                    width: "100%",
                    flexWrap: "wrap"
                  }
                }}>
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        All
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Beauty
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        News
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Business
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Sales
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        SocialMedia
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Presentation
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Education
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Event
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        Hobby
                      </VuiTypography>
                    }
                  />
                  <Tab
                    sx={{ flex: 1 }}
                    label={
                      <VuiTypography color="light" variant="body2">
                        ChatGPT
                      </VuiTypography>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
            {tabValue === 0 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column'}}>
                <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                  {
                    template.slice(0, showMore ? template.length : visibleItems).map((item, index) => (
                      <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', marginBottom: '15px'}} key={index}>
                        <Button sx={{padding: '10px'}}>
                          <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                        </Button>
                        <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                      </VuiBox>
                    ))
                  }
                </VuiBox>
                {template.length > 10 && <Button onClick={toggleShowMore} variant='contained' sx={{width: '200px', color: 'whitesmoke', marginTop: '20px'}}>{showMore ? 'Show Less' : 'Show More'}</Button>}
              </VuiBox>
            )}
            {tabValue === 1 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  beautyTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 2 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  newsTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 3 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  businessTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 4 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  salesTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 5 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  socialTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 6 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  presentationTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 7 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  educationTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 8 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  eventTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 9 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                {
                  hobbyTemplate.map((item, index) => (
                    <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center'}} key={index}>
                      <Button sx={{padding: '10px'}}>
                        <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                      </Button>
                      <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                    </VuiBox>
                  ))
                }
              </VuiBox>
            )}
            {tabValue === 10 && (
              <VuiBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column'}}>
                <VuiBox sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', maxHeight: '100vh', overflowY: 'auto', padding: '0 20px'}}>
                  {
                    chatTemplate.slice(0, showMore ? chatTemplate.length : visibleItems).map((item, index) => (
                      <VuiBox sx={{display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', marginBottom: '15px'}} key={index}>
                        <Button sx={{padding: '10px'}}>
                          <img src={item.src} alt="icons" width={200} height={110} style={{border: '0.1px solid black', borderRadius: '5px'}} />
                        </Button>
                        <Typography variant='h6' color='whitesmoke' sx={{marginLeft: '15px'}}>{item.title}</Typography>
                      </VuiBox>
                    ))
                  }
                </VuiBox>
                {chatTemplate.length > 10 && <Button onClick={toggleShowMore} variant='contained' sx={{width: '200px', color: 'whitesmoke', marginTop: '20px'}}>{showMore ? 'Show Less' : 'Show More'}</Button>}
              </VuiBox>
            )}
          </VuiBox>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', marginTop: '50px'}}>
            <VuiTypography variant='h5' color= 'white' sx={{marginBottom: '20px'}}>My Project</VuiTypography>
            <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center'}}>
              <VuiTypography variant='h6' color= 'white' sx={{marginBottom: '20px'}}>Please make a video</VuiTypography>
              <Button variant='contained' sx={{color: 'whitesmoke'}} onClick={()=>setCreateVideo(true)}> New Project </Button>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      }
      {createVideo && <EditingSection 
        lastSaved={lastSaved}
        handleSave={handleSave}
        plan={plan}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        handleLeaveClick={handleLeaveClick}
        setCreateVideo={setCreateVideo} 
        setSelectBreak={setSelectBreak}
        setSelectSpeed={setSelectSpeed}
        changeInput={changeInput}
        setChangeInput={setChangeInput}
        videoName={videoName}
        setVideoName={setVideoName}
        setSelectLanguage={setSelectLanguage}
        setSelectVoice={setSelectVoice}
        voiceOption={voiceOption}
        setVoiceOption={setVoiceOption}
        touched={touched}
        setTouched={setTouched}
        alignment={alignment}
        setAlignment={setAlignment}
        value={value}
        setValue={setValue}
        fonts={fonts}
        setFonts={setFonts}
        selectedFont={selectedFont}
        setSelectedFont={setSelectedFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
        selectedFontSize={selectedFontSize}
        setSelectedFontSize={setSelectedFontSize}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        stockImages={stockImages}
        setStockImages={setStockImages}
        imageQuery={imageQuery}
        setImageQuery={setImageQuery}
        page={page}
        setPage={setPage}
        imageObserver={imageObserver}
        videoObserver={videoObserver}
        lastImageRef={lastImageRef}
        loading={loading}
        backgroundTabValue={backgroundTabValue}
        setBackgroundTabValue={setBackgroundTabValue}
        uploadedVideo={uploadedVideo}
        setUploadedVideo={setUploadedVideo}
        stockVideos={stockVideos}
        setStockVideos={setStockVideos}
        videoQuery={videoQuery}
        setVideoQuery={setVideoQuery}
        videoPage={videoPage}
        setVideoPage={setVideoPage}
        lastVideoRef={lastVideoRef}
        uploadedAudio={uploadedAudio}
        setUploadedAudio={setUploadedAudio}
        models={models}
        setModels={setModels}
        imageTotalPages={imageTotalPages}
        videoTotalPages={videoTotalPages}
        items={items}
        setItems={setItems}
        selectedAudio={selectedAudio}
        setSelectedAudio={setSelectedAudio}
        faceModels={faceModels}
        scenes={scenes}
        setScenes={setScenes}
        updateSceneKey={updateSceneKey}
        addScene={addScene}
        deleteScene={deleteScene}
        handleBoxClick={handleBoxClick}
        currentSceneIndex={currentSceneIndex}
        handleSubmit={handleSubmit}
        handleFinalSubmit={handleFinalSubmit}
        componentRef={componentRef}
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
        stockShapes={stockShapes}
        setStockShapes={setStockShapes}
        shapesQuery={shapesQuery}
        setShapesQuery={setShapesQuery}
        indexToStore={indexToStore}
        setIndexToStore={setIndexToStore}
        loadingModal={loadingModal}
        setLoadingModal={setLoadingModal}
        calculateDimensions={calculateDimensions}
      />}
      {plan !== 'Trial' && !createVideo && welcomeModal && <WelcomeModal welcomeModal={welcomeModal} setWelcomeModal={setWelcomeModal} setCreateVideo={setCreateVideo} />}
      {confirmModal && <ConfirmationModal confirmModal={confirmModal} setConfirmModal={setConfirmModal} handleFinalSubmit={handleFinalSubmit} finalApiHit={finalApiHit} setFinalApiHit={setFinalApiHit} />}
    </>
  )
}

export default VideoEditor